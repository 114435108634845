import type { PublicPlan } from '@wix/ambassador-pricing-plans-v2-plan/types';
import type {
  PricingPlansPublicAPI,
  NavigateToPackagePickerOptions,
  NavigateToCheckoutOptions,
} from '@wix/pricing-plans-tpa-api';
import { getPlanPrice } from '@wix/pricing-plans-utils';
import type { ViewerScriptFlowAPI, InitAppForPageFn, TFunction } from '@wix/yoshi-flow-editor';
import { EXPERIMENTS } from './constants';
import { encodeBase64Url } from './services/encode-base64';
import type { CheckoutData, IntegrationData, NavigateToSectionProps } from './types/common';
import { resolveCurrencyLocale } from './utils';
import { getFreeTrialDaysLabel } from './utils/getFreeTrialDaysLabel';
import { getPeriodLabel } from './utils/getPeriodLabel';
import { getPlanDuration } from './utils/getPlanValidityCycle';
import { integrationDataToAppSectionParams } from './utils/integrationData';

let flowAPI: ViewerScriptFlowAPI;

export const initAppForPage: InitAppForPageFn = async (initParams, apis, namespaces, platformServices, _flowAPI) => {
  flowAPI = _flowAPI;
};

export const exports: PricingPlansPublicAPI = {
  getTranslatedPricingDetails(plan: PublicPlan) {
    const t = flowAPI.translations.t as TFunction;
    const { value, currency } = getPlanPrice(plan);
    const locale = resolveCurrencyLocale(flowAPI.wixAPI);
    const formatCurrency = flowAPI.experiments.enabled(EXPERIMENTS.PRICE_FORMATTER)
      ? flowAPI.getCurrencyFormatter({ language: locale })
      : flowAPI.formatCurrency;
    const formattedPrice = value && currency ? (formatCurrency({ value, currency }) as string) : '';
    return {
      price: formattedPrice,
      cycle: getPeriodLabel(plan.pricing?.subscription, t) ?? '',
      duration: getPlanDuration(plan, t),
      freeTrial: getFreeTrialDaysLabel(plan, t),
    };
  },
  async navigateToPackagePicker(options: NavigateToPackagePickerOptions) {
    const { relativeUrl } = await flowAPI.wixAPI.site.getSectionUrl({
      sectionId: 'membership_plan_picker_tpa',
      appDefinitionId: '1522827f-c56c-a5c9-2ac9-00f9e6ae12d3',
    });

    flowAPI.wixAPI.location.to!(relativeUrl! + '?' + integrationDataToAppSectionParams(buildIntegrationData(options)));
  },
  async navigateToCheckout(options: NavigateToCheckoutOptions) {
    const { planId, biOptions, ...checkout } = options;
    const { relativeUrl } = await flowAPI.wixAPI.site.getSectionUrl({
      sectionId: 'membership_plan_picker_tpa',
      appDefinitionId: '1522827f-c56c-a5c9-2ac9-00f9e6ae12d3',
    });

    flowAPI.wixAPI.location.to!(
      relativeUrl! +
        '/payment/' +
        encodeBase64Url({
          integrationData: buildIntegrationData({
            planIds: [planId],
            checkout,
            biOptions,
          }),
          planId,
        } as CheckoutData),
    );
  },
};

const buildIntegrationData = (options: NavigateToPackagePickerOptions): IntegrationData => {
  const data: IntegrationData = {
    planIds: options?.planIds,
    title: options?.title,
    subtitle: options?.subtitle,
    minStartDate: options?.checkout?.minStartDate,
    maxStartDate: options?.checkout?.maxStartDate,
  };
  if (options?.checkout?.successStatus?.content) {
    data.verticalStatusContent = {
      titleText: options.checkout.successStatus.content.title,
      buttonText: options.checkout.successStatus.content.cta,
    };
    if (options.checkout.successStatus.content.cta) {
      data.verticalStatusContent.contentText = options.checkout.successStatus.content.message;
    }
  }
  if (options?.checkout?.successStatus?.navigation?.type === 'url') {
    data.navigateTo = options.checkout.successStatus.navigation.url;
  } else if (options?.checkout?.successStatus?.navigation?.type === 'page') {
    data.navigateToPageProps = options.checkout.successStatus.navigation.pageId;
  } else if (options?.checkout?.successStatus?.navigation?.type === 'section') {
    data.navigateToSectionProps = options.checkout.successStatus.navigation.options as NavigateToSectionProps;
  }
  if (options?.biOptions) {
    data.biOptions = {
      referralInfo: options.biOptions.referralInfo,
      referralId: options.biOptions.referralId,
    };
  }
  return data;
};
